







































import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract/ui'
import { ImageProps } from '../../../dsl/atoms/Image'
import { toImageProps } from '../../../front/shared/support'

import { UiMarkdown } from '../../partials/UiMarkdown'

import { CTABoxModule } from '../CTABox.contracts'
import { Inject } from '../../../support'
import { EventbusType, IEventbus } from '@movecloser/front-core'
import YouTubeModal from '../../partials/YouTubeModal/YoutubeModal.vue'

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
@Component<CTABoxModuleUi>({
  name: 'CTABoxModuleUiVideo',
  components: { UiMarkdown, YouTubeModal },
  mounted (): void {
    this.videoIdentifier = this.getIdentifier(this.videoId!, String(Math.random() * 1000))
  }
})
export class CTABoxModuleUi extends AbstractModuleUi<CTABoxModule> {
  @Inject(EventbusType)
  public eventBus!: IEventbus

  public get direction (): string {
    return this.content.direction
  }

  public get image (): ImageProps {
    if (!this.content.image) {
      return this.youTubeCover
    }

    return toImageProps(this.content.image)
  }

  public get youTubeCover (): ImageProps {
    return {
      src: `https://img.youtube.com/vi/${this.videoId}/0.jpg`,
      alt: 'YouTube cover video'
    }
  }

  public get cta () {
    return this.content.cta
  }

  public get videoId () {
    return this.content.videoId
  }

  public videoIdentifier: string | null = null

  public getIdentifier (videoId: string, index: string) {
    return `${videoId}-${index}`
  }

  public onPlayBtnClick (): void {
    this.eventBus?.emit('play', { videoIdentifier: this.videoIdentifier })
  }
}

export default CTABoxModuleUi
